<template>
  <Modal :value="visible"
         :title="item ? '【'+item.carNumber+'】'+item.planName : ''"
         width="1200"
         footer-hide
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="equipment">
      <Table ref="table"
             :columns="getColumns"
             :data="data"
             height="600"
             border
             stripe
             :loading="tableLoading"
             @on-selection-change="onChangeSelect">
      </Table>
      <Page class="m-t-10 m-l-10"
            :total="totalPage"
            :current="pageNum"
            :page-size="pageSize"
            show-total
            show-sizer
            show-elevator
            @on-change="onPageChange"
            @on-page-size-change="onPageSizeChange" />
    </div>
  </Modal>
</template>

<script>
import MyTreeSelect from '@/components/common/MyTreeSelect';
export default {
  components: { MyTreeSelect },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: String,
    item: null,
    workType: Number,
    date: Array
  },
  data () {
    return {
      mechanicalCleanColumns: [
        // {
        //   type: 'selection',
        //   width: 45,
        //   align: 'center'
        // },
        {
          title: '序号',
          width: 60,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '日期',
          key: 'workDate',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        // {
        //   title: '班组时段',
        //   key: 'name',
        //   tooltip: true,
        //   minWidth: 200,
        //   align: 'center'
        // },
        {
          title: '考勤段',
          minWidth: 60,
          key: 'shift',
          tooltip: true,
          align: 'center'
        },
        {
          title: '排班开始时间',
          minWidth: 110,
          key: 'workPlanStartTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '排班结束时间',
          minWidth: 110,
          key: 'workPlanEndTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '作业开始时间',
          key: 'startWorkTime',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '作业结束时间',
          key: 'endWorkTime',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '作业时长',
          key: 'workTime',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '作业完成率(%)',
          key: 'completePercent',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
      ],
      garbageCleanColumns: [
        // {
        //   type: 'selection',
        //   width: 45,
        //   align: 'center'
        // },
        {
          title: '序号',
          width: 40,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '日期',
          key: 'date',
          tooltip: true,
          minWidth: 80,
          align: 'center'
        },
        {
          title: '考勤段',
          minWidth: 60,
          key: 'shiftName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '排班开始时间',
          minWidth: 120,
          key: 'planStartTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '排班结束时间',
          minWidth: 120,
          key: 'planEndTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '作业时长',
          key: 'workTime',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '作业开始时间',
          key: 'workStartTime',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '作业结束时间',
          key: 'workEndTime',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '完成情况',
          minWidth: 60,
          key: 'planName',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.status == 3 ? '已完成' : '未完成')
          }
        },
        {
          title: '作业完成率(%)',
          key: 'clearanceCompletionRate',
          tooltip: true,
          minWidth: 80,
          align: 'center'
        },
        // {
        //   title: '里程完成率(%)',
        //   key: 'completePercent',
        //   tooltip: true,
        //   minWidth: 80,
        //   align: 'center',
        //   render: (h, params) => {
        //     let row = params.row
        //     return h('span', '--')
        //   }
        // },
      ],
      garbageTransitTransferStationColumns: [
        {
          title: '序号',
          width: 40,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '日期',
          key: 'workDate',
          tooltip: true,
          minWidth: 80,
          align: 'center'
        },
        {
          title: '中转站',
          minWidth: 60,
          key: 'carPlanProcessStandList',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let str = ""
            for (let item of row.carPlanProcessStandList) {
              str += item['facilityName']
            }
            return h('span', str)
          }
        },
        {
          title: '中转时长',
          minWidth: 120,
          key: 'timeConsumption',
          tooltip: true,
          align: 'center'
        },
        {
          title: '中转开始时间',
          minWidth: 120,
          key: 'workStartTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '中转结束时间',
          key: 'workEndTime',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
      ],
      garbageTransitProcessingStationColumns: [
        {
          title: '序号',
          width: 40,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '日期',
          key: 'workDate',
          tooltip: true,
          minWidth: 80,
          align: 'center'
        },
        {
          title: '处理站',
          minWidth: 60,
          key: 'carPlanCollectStandList',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let str = ""
            for (let item of row.carPlanCollectStandList) {
              str += item['facilityName']
            }
            return h('span', str)
          }
        },
        {
          title: '处理时长',
          minWidth: 120,
          key: 'timeConsumption',
          tooltip: true,
          align: 'center'
        },
        {
          title: '处理开始时间',
          minWidth: 120,
          key: 'workStartTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '处理结束时间',
          key: 'workEndTime',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
      ],
      mechanicalCleanCarColumns: [
        {
          title: '序号',
          width: 40,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '日期',
          key: 'workDate',
          tooltip: true,
          minWidth: 80,
          align: 'center'
        },
        {
          title: '排班时段',
          minWidth: 60,
          key: 'shiftName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '排班开始时间',
          minWidth: 120,
          key: 'planStartTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '排班结束时间',
          minWidth: 120,
          key: 'planEndTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '出车时间',
          key: 'carStartTime',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '收车时间',
          key: 'carEndTime',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '作业开始时间',
          key: 'workStartTime',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '作业结束时间',
          key: 'workEndTime',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '作业时长',
          key: 'workTime',
          tooltip: true,
          width: 80,
          align: 'center'
        },
        {
          title: '完成状态',
          key: 'status',
          tooltip: true,
          width: 80,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.status == 3 ? '已完成' : '未完成')
          }
        },
        {
          title: '作业完成度',
          key: 'completePercent',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
      ],
      garbageTransitCarColumns: [
        {
          title: '序号',
          width: 40,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '日期',
          key: 'date',
          tooltip: true,
          minWidth: 80,
          align: 'center'
        },
        {
          title: '排班时段',
          width: 90,
          key: 'shiftName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '排班开始时间',
          minWidth: 120,
          key: 'planStartTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '排班结束时间',
          minWidth: 120,
          key: 'planEndTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '出车时间',
          key: 'outCarTime',
          tooltip: true,
          width: 90,
          align: 'center'
        },
        {
          title: '收车时间',
          key: 'closeCarTime',
          tooltip: true,
          width: 90,
          align: 'center'
        },
        {
          title: '作业开始时间',
          key: 'workStartTime',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '作业结束时间',
          key: 'workEndTime',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '作业时长',
          key: 'workTime',
          tooltip: true,
          width: 110,
          align: 'center'
        },
        {
          title: '完成状态',
          key: 'status',
          tooltip: true,
          width: 80,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.status == 3 ? '已完成' : '未完成')
          }
        },
        {
          title: '作业完成度',
          key: 'clearanceCompletionRate',
          tooltip: true,
          width: 90,
          align: 'center'
        },
      ],
      data: [],
      pageNum: 1,
      pageSize: 20,
      totalPage: 0,
      tableLoading: false,
      loading: false,
    }
  },
  watch: {
    visible (newVal) {
      newVal && this.getList()
    },
  },
  computed: {
    getColumns () {
      let columns = []
      // 2机械作业 3垃圾清运 4垃圾转运中转站 5垃圾转运处理站 6机械作业车辆统计 7垃圾转运明细点击车牌号
      switch (this.workType) {
        case 2:
          columns = this.mechanicalCleanColumns
          break;
        case 3:
          columns = this.garbageCleanColumns
          break;
        case 4:
          columns = this.garbageTransitTransferStationColumns
          break;
        case 5:
          columns = this.garbageTransitProcessingStationColumns
          break;
        case 6:
          columns = this.mechanicalCleanCarColumns
          break;
        case 7:
          columns = this.garbageTransitCarColumns
          break;
        default:
          break;
      }
      return columns
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
    },
    getList () {
      switch (this.workType) {
        case 2:
          this.getMechanicalCleanList()
          break;
        case 3:
          this.getGarbageCleanList()
          break;
        case 4:
          this.getCollectStandNumberList()
          break;
        case 5:
          this.getProcessStandList()
        case 6:
          this.getMechanicalCleanCarList()
          break;
        case 7:
          this.getGarbageTransitCarList()
          break;
        default:
          break;
      }
    },
    // 机械清扫
    getMechanicalCleanList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        carId: this.item.carId,
        planId: this.item.planId,
        startDate: this.date[0],
        endDate: this.date[1],
        // workDate: this.item.workDate
      }
      this.tableLoading = true
      this.$http.getMechanicalPlanDetailTangciList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.list
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 垃圾清运
    getGarbageCleanList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        carId: this.item.carId,
        planId: this.item.planId,
        startDate: this.date[0],
        endDate: this.date[1],
        // date: this.item.date
      }
      this.tableLoading = true
      this.$http.getGarbageCleanDetailTangciList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.list
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 中转站
    getCollectStandNumberList () {
      console.log(item);
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        carId: this.item.carId,
        planId: this.item.planId,
        startDate: this.date[0],
        endDate: this.date[1],
      }
      this.tableLoading = true
      this.$http.getCollectStandNumberList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.list
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 处理站
    getProcessStandList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        carId: this.item.carId,
        planId: this.item.planId,
        startDate: this.date[0],
        endDate: this.date[1],
        // workDate: this.item.workDate
      }
      this.tableLoading = true
      this.$http.getProcessStandList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.list
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 机械清扫 车辆统计
    getMechanicalCleanCarList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        carId: this.item.carId,
        planId: this.item.planId,
        startDate: this.date[0],
        endDate: this.date[1],
        // workDate: this.item.workDate
      }
      this.tableLoading = true
      this.$http.getMechanicalCleanCarList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.list
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 垃圾转运 明细 点击车牌号
    getGarbageTransitCarList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        carId: this.item.carId,
        planId: this.item.planId,
        startDate: this.date[0],
        endDate: this.date[1],
        // workDate: this.item.workDate
      }
      this.tableLoading = true
      this.$http.getGarbageTransitCarList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.list
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // tab多选
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:item', null)
      }
    },
    resetData () {
      this.pageNum = 1
      this.pageSize = 20
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
