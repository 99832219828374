<template>
  <Modal :value="visible"
         @on-visible-change="onChangeVisible"
         :title="title"
         width="450"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <div class="title-tip">
        <Icon custom="i-icon icon-jingshi"
              size="15"
              color="red" />
        修改后，完成度计算将于下一班次生效，不影响历史数据
      </div>
      <ul class="form-ul">
        <li class="form-ul-li"
            :class="{half:type != 1}"
            v-if="type != 2">
          <p class="li-title">里程完成率阈值：</p>
          <Input v-model="kmOkThreshold"
                 placeholder="里程完成率阈值"
                 class="input-right-5"></Input><span class="li-unit">%</span>
        </li>
        <!-- <li class="form-ul-li half"
            v-if="type != 1 && type != 2 && type != 5">
          <Radio v-model="mileage"
                 @on-change="onChangeMileage">环卫一张网显示</Radio>
        </li> -->
        <li class="form-ul-li half"
            v-if="type == 2">
          <p class="li-title">路径完成率阈值：</p>
          <Input v-model="routeOkThreshold"
                 placeholder="路径完成率阈值"
                 class="input-right-5"></Input><span class="li-unit">%</span>
        </li>
        <!-- <li class="form-ul-li half"
            v-if="type != 1">
          <Radio v-model="path"
                 @on-change="onChangePath">环卫一张网显示</Radio>
        </li> -->
        <li class="form-ul-li half"
            v-if="type != 1 && type != 2 && type != 3 && type != 5">
          <p class="li-title">点位完成率阈值：</p>
          <Input v-model="pointOkThreshold"
                 placeholder="点位完成率阈值"
                 class="input-right-5"></Input><span class="li-unit">%</span>
        </li>
        <!-- <li class="form-ul-li half"
            v-if="type != 1 && type != 2 && type != 3 && type != 5">
          <Radio v-model="point"
                 @on-change="onChangePoint">环卫一张网显示</Radio>
        </li> -->
        <!-- <li class="form-ul-li"
            v-if="type == 2">
          <p class="li-title">运动时长完成率阈值：</p>
          <Input v-model="motionOkThreshold"
                 placeholder="运动时长完成率阈值"
                 class="input-right-5"></Input><span class="li-unit">%</span>
        </li> -->
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '完成度计算设置'
    },
    type: Number
  },
  data () {
    return {
      loading: false,
      kmOkThreshold: '',
      routeOkThreshold: '',
      pointOkThreshold: '',
      motionOkThreshold: '',
      mileage: false,
      path: false,
      point: false,
      bsShow: ''
    };
  },
  watch: {
    type (newVal) {
      newVal && this.getDetail()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    // 获取详情
    getDetail () {
      this.$store.dispatch('getTimeCompleteDetail', this.type).then(res => {
        for (const i in res) {
          this[i] = res[i]
        }
        if (res.bsShow == 1) {
          this.mileage = true
        } else if (res.bsShow == 2) {
          this.path = true
        } else if (res.bsShow == 3) {
          this.point = true
        }
      })
    },
    // 里程
    onChangeMileage () {
      this.bsShow = 1
      this.path = false
      this.point = false
    },
    // 路径
    onChangePath () {
      this.bsShow = 2
      this.mileage = false
      this.point = false
    },
    // 点位
    onChangePoint () {
      this.bsShow = 3
      this.mileage = false
      this.path = false
    },
    // 确认
    onClickConfirm () {
      this.loading = true
      let params = {
        jobsetId: this.type,
        kmOkThreshold: this.kmOkThreshold,
        routeOkThreshold: this.routeOkThreshold,
        pointOkThreshold: this.pointOkThreshold,
        motionOkThreshold: this.motionOkThreshold
      }
      if (this.type != 1) params.bsShow = this.bsShow
      this.$store.dispatch('createOrEditTimeComplete', params).then(res => {
        this.loading = false
        // this.$store.dispatch('updatePersonTreeList', true)
        this.$emit('onClickConfirm')
        this.$emit('onChange', false)
      }).catch(err => {
        this.loading = false
      })
    },
    // 取消
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      // !boo && this.$emit('onChange', false)
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:type', null)
        this.resetData()
      }
    },
    resetData () {
      this.kmOkThreshold = ''
      this.routeOkThreshold = ''
      this.pointOkThreshold = ''
      this.mileage = false
      this.path = false
      this.point = false
      this.bsShow = ''
    },
  },
}
</script>

<style lang='scss' scoped>
.title-tip {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
</style>